@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

h1 {
    font-size: 1.3em;
    line-height: 100%;
    font-weight: 500;
}

.rebates-container {
    background-color: #FFFFFF;
    display: flex;
    height: 417px;
    padding: 64px 24px 64px 24px;
    gap: 80px;

}

.rebates-left-column {
    flex: 0 0 auto; /* Adjust the flex value to change the width ratio between columns */
    /* Add more styles here as needed */
    // width: 576px;
    padding: 64px;
height: 289px;
gap: 24px;
color: #002F57;
font-family: Oswald;
font-size: 61px;
font-weight: 500;
line-height: 61px;
letter-spacing: 0em;
text-align: left;
width: 576px;
height: 183px;


}

.rebates-right-column {
    flex: 1 0 auto; /* Adjust the flex value to change the width ratio between columns */
    /* Add more styles here as needed */
}

.offer-code-form {
    // width: 576px; /* Fill width to 576px */
    width: 80%;
    height: auto; /* Hug content height */
    max-height: 207px; /* Maximum height of 207px */
    background-color: #E6F1FA; /* Light blue background color */
    padding: 48px; /* Padding all around */
    margin: 50px;
    border-radius: 24px; /* Rounded corners */
    box-shadow: 10px 10px 20px 5px rgba(169, 199, 211, 0.75);

}
.offer-form-header{
    font-family: Oswald;
font-size: 39px;
font-weight: 500;
line-height: 39px;
letter-spacing: 0px;
text-align: left;
color: #002F57;

}

.left-column-image-container {
    width: 100%;
}



.offer-code-form-custom{
    display: flex;
    text-align: left;

}
.offer-code-form-custom {
    margin: 25px;
    margin-right: 0px;
    margin-left: 0px;
    width: 346px;
    // height: 355px !important;
    border-radius: 4px;
    border: 1px;

    input{
        border: 1px solid #E0E6EB;
    }
}

.offer-button-container button {
    color: #FFFFFF;
    background: #0071CE;
}


.lookup-form-container{
    height: 294px;
    padding: 48px 24px 48px 24px;
    gap: 80px;
    background: #F5F7F9;
}

.lookup-form-content h1{
    font-family: Oswald;
    font-size: 49px;
    font-weight: 500;
    line-height: 73px;
    letter-spacing: 0px;
    text-align: center;
    color: #002F57;
}

.lookup-form-content h3{
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: center;
    color: #1E1B18;
    
}
.lookup-form-custom {
    display: flex;
    justify-content: center; 

    form {
        margin: 5px;
        border: 1px solid #E0E6EB !important;
    }
    input {
    padding: 3px;
    margin: 5px;
    width: 250px;
    height: 45px;
    border-color: #E0E6EB;
    }
}
.lookup-button-container button {
    color: linear-gradient(0deg, #0071CE, #0071CE) !important;
    margin: 5px;
    height: 45px;
}

.submission-form-container {
    color: #002F57;
    background: #FFFFFF;
    // flex: 0 0 50%; /* Set the right column to 50% width */
    display: flex; /* Add display:flex to the container */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    // padding: 50 24px;
    // padding-left: 150px;
    // padding-right: 150px;
    margin: 0 auto;
    padding: 24px;

    display: block; /* Use flexbox to create a two-column layout */
    justify-content: flex-start;
    align-items: flex-start;
    
}

.claim-your-rebate-header{
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    color: #002F57;
    background: #FFFFFF;
    font-family: Oswald;
    font-size: 49px;
    font-weight: 500;
    line-height: 73px;
    letter-spacing: 0px;
    text-align: center;
}

.image-column-container{
    width: 100%
}

.submission-form-column-left {
    flex: 0 0 50%; /* Set the left column to 60% width */
}

.submission-form-column-right {
    flex: 0 0 50%; /* Set the right column to 40% width */
    // padding-right: 164px;
}

.right-column-image-text {
    // flex: 0 0 50%;
    // padding: 10px; /* Add padding for spacing */
    width: 100%;
}



.save-header{
    font-family: Oswald;
font-size: 25px;
font-weight: 600;
line-height: 39px;
letter-spacing: 0px;
text-align: left;

}

.save-subheader{
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 0px;
    
}

.expiration-date{
    margin-top: 10px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: #6D7B88;

    
}

.right-column-disclaimer{
    color: #002F57;
    font-family: Roboto;
font-size: 13px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0px;
text-align: left;
// padding-left: 64px;
// padding-right: 164px;
// padding-top: 20px;
// padding-bottom: 64px;

}

.submission-form-header{
    h2{
        font-family: Oswald;
        font-size: 31px;
        font-weight: 600;
        // font-size: 49px;
        // font-weight: 500;
        line-height: 73px;
        letter-spacing: 0px;
        text-align: center;

    }

    .mail-in-text{
        font-family: Roboto;
font-size: 20px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0px;
text-align: center;
color: #000000;
    }

    .submission-form-header-subtext{
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: center;
        color: #6D7B88;
    }

}
.product-upc{
    font-family: Oswald;
font-size: 21px;
font-weight: 500;
line-height: 31px;
letter-spacing: 0px;
text-align: left;
color: #0071CE;

}

.product-upc-desc{
    text-align: left;
}

#upc-module-container{
    border: 1px solid lightgrey;
    // padding: 5px;
    border-radius: 12px;
    // height: 444px;
}

#search-bar {
    border: 1px solid lightgrey;
    border-radius: 8px;
    height: 40px;
}

.upc-list-container{
    border-radius: 8px;
    padding: 10px;
    border: 1px solid lightgrey;

}

#custom-scrollbar{
    height: 444px;
}

.rebates-custom-button {
    width: 100%;
    height: 71px;
    padding: 24px 16px;
    border-radius: 12px;
    border: 1px solid;
    gap: 10px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: center;
    border: 1px solid #D1D9E2 !important;
    color: #0071CE;
    background-color: white !important;
}

.rebates-custom-button:hover{
    color: #0071CE;

    border: 1px solid lightgrey;
    background-color: white !important;
    
}

.selected-button {
    height: 71px;
    border-radius: 12px;
    border: 1px solid;
    background: linear-gradient(0deg, #D1D9E2, #D1D9E2), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  }
.selected-button:hover {
    background-color: #0071CE !important; /* Change to your desired blue color */
    color: #0071CE !important; /* Text color for contrast */
  }

  .question-group{
      padding-top: 10px;
  }

  .label-left{
      text-align: left !important;
  }

  .button-container {
    // display: flex;
    flex-wrap: wrap;
    text-align: right;
  }

  .third-width {
      display: block;
      width: 100%;
      box-sizing: border-box;
      border-radius: 12px;
      padding: 24px;
      margin: 5px auto;
      // border: 1px solid #0071CE;
      color: black;
      background-color: white;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      letter-spacing: 0px;
      
  }

  .half-width {
    display: block;
    width: 80%;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 24px;
    margin: 0 auto;
    // border: 1px solid #0071CE;
    color: black;
    background-color: white;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0px;
    
}

.half-width:hover {
    // border: 1px solid #0071CE;
    color: #0071CE;
    background-color: white;
}
.full-width:hover {
    border: 1px solid #0071CE;
    color: #0071CE;
    background-color: white;
    
}

.full-width {
    box-sizing: border-box;
    border-radius: 12px;
    color: #000000;
    border: 1px solid lightgray;
    padding: 24px;
    margin: 5px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 10px; 
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0px;
    background-color: white;
}

#review-button{
    border: 1px solid #0071CE;
    color: #FFFFFF;
    background-color: #0071CE;
    margin-bottom: 5px;
}

.with-spacing{
    margin-top: 15px;
}

.form-label{
    margin: 0px !important;
    font-family: Roboto;
font-size: 14px;
font-weight: 500;
line-height: 16px;
letter-spacing: 0px;
text-align: left;
color: black;

}

.red{
    color: red;
}

#submit-receipt-container {
    display: flex;
    align-items: center; /* Align vertically in the middle */
    // new for mobile
    justify-content: center;
}

#submit-receipt {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    // increase horizontal padding from 24 to 64 for mobile
    padding: 16px 64px;
    border-radius: 4px;
    gap: 10px;
    background-color: #0071CE;
    color: white; /* Text color (white for contrast) */
    flex: 0; /* Allow the button to occupy as little space as needed */
    white-space: nowrap;
    margin-top: 10px;
    margin-bottom: 10px;
}


.MuiDropzoneArea-root{
    border: 3px dashed #1D3C6D !important;
}

.custom-dropzone-text {
    font-family: Lato,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Cabin;   
}

.MuiDropzoneArea-textContainer svg{
    display: none;
}

#drag-and-drop-button{
    font-family: Roboto;
font-size: 20px;
font-weight: 700;
line-height: 23px;
letter-spacing: 0px;
text-align: center;
background: #0071CE !important;

}

.form-check-label{
font-family: Roboto;
font-size: 20px;
font-weight: 700;
line-height: 23px;
letter-spacing: 0px;
text-align: left;

font-family: Roboto;
font-size: 20px;
font-weight: 400;
line-height: 23px;
letter-spacing: 0px;
text-align: left;

}

.receipt-upload-container {
    display: flex;
    align-items: left;
    margin: 5px;
}

.receipt-example-image {
    height: auto;
}

.image-column {
    flex: 1;
    text-align: left;
}

.info-column {
    flex: 2;
    text-align: left;
    padding-left: 15px;
}

.receipt-upload-date {
    font-family: Roboto;
font-size: 12px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0px;
text-align: left;
color: #8C9DAE;


}

.rebates-failed-popup-header{
    font-family: Oswald;
font-size: 39px;
font-weight: 500;
line-height: 43px;
letter-spacing: 0px;
text-align: center;
padding: 50px;
padding-top: 0px;
padding-bottom: 0px;
}

.rebates-failed-popup-text{
    padding: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
}

.rebates-success-popup-header{
    font-family: Oswald;
font-size: 39px;
font-weight: 500;
line-height: 43px;
letter-spacing: 0px;
text-align: center;
padding: 20px;
padding-top: 0px;
padding-bottom: 0px;
}

.rebates-success-popup-text{
    padding: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
}


#popup-close-button button{
    color: none;
}

.success-details{
    width: 100%;
    padding: 20px;
}

.your-details{
    padding-top: 20px;
    text-align: left;
    h6{
        text-align: left;
    }
}

.payment-details{
    padding-top: 20px;
    text-align: left;
}

.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }

  .file-preview img{
    //   width: 300px;
    width: 160px;
    height: 160px;
  }

  #cancel-button{
    color: #0071CE;
    
}

#cancel-button:hover{
    color: white;
}

#edit-button{
      color: #0071CE;
    border: 1px solid #0071CE;
    background-color: white;
  }

  #edit-button:hover{
      color: white;
      background-color: #0071CE;
  }

/* Base styles - assume single column layout on mobile */
.image-column-container,
.right-column-image-text {
  /* Take full width on smaller screens */
  width: 100%;
}

/* Ensure the image is responsive */
.left-column-image-container img {
    height: 240px;
    width: 342px; 
}

/* Override styles for larger screens */
@media (min-width: 768px) {
    .submission-form-container {
        color: #002F57;
        display: flex; /* Use flexbox to create a two-column layout */
        background: #FFFFFF;
        flex: 0 0 50%; /* Set the right column to 50% width */
        display: flex; /* Add display:flex to the container */
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        padding: 50px;
        padding-left: 150px;
        padding-right: 150px;
        margin: 0px;
        
    }

  .image-column-container,
  .right-column-image-text {
    /* Set widths to 50% or whatever ratio you need for two columns */
    flex-basis: 50%;
    /* Optional: Use this if you want fixed widths instead of flexible ones */
    /* max-width: 50%; */
  }

  .image-column-container {
    /* If you need any additional styling for the image container or image on larger screens */
  }

  .right-column-image-text {
    flex-basis: 50%;
    }

  /* Ensure the content inside the right column is aligned as desired, e.g., left or center */
  .right-column-image-text {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically, if needed */
    align-items: flex-start; /* Align text to the start, change if centering is needed */
  }
  .save-header{
    font-family: Oswald;
    font-size: 39px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0px;
    text-align: left;
}
  .save-subheader {
    margin-top: 10px;
  }
  #submit-receipt {
    padding: 16px 24px;
  }
  .submission-form-header{
    h2{
        font-size: 49px;
        font-weight: 500;
        text-align: left;
    }

  }
  .submission-form-header-subtext{
    text-align: left;
  }
  .button-container {
    display: flex;
    justify-content: center;
  }
  .half-width {
    width: 40%;
    margin: 5px;
  }
  .claim-your-rebate-header{
    font-size: 34px;
  }
  .third-width {
    width: 30%;
    margin: 5px;
  }
}

