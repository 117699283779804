.offer2 {
  overflow: hidden;
  line-height: 1.2;
  display: flex;
  position: relative;
  width: 23%;
  margin: 0 1% 2%;
  border-radius: 24px;
  border: 1px solid var(--neutral-50, #EEF0F2);
  box-shadow: 0px 0px 0px 0px rgba(29, 60, 109, 0.10), 0px 7px 15px 0px rgba(29, 60, 109, 0.10), 0px 61px 37px 0px rgba(29, 60, 109, 0.05), 0px 109px 44px 0px rgba(29, 60, 109, 0.01), 0px 170px 48px 0px rgba(29, 60, 109, 0.00);
  width: 294px;
  height: 481px;
  font-family: 'Switzer';
  text-align: left;

  .content {
    font-size: 0.8em;
    padding: 1rem 1rem 0.5rem;
  }

  .offer-img {
    object-fit: cover;
    // width: auto;
    width: 246px;
    height: 186px;
    padding: var(--space--lg, 24px) var(--space--lg, 24px) var(--space--lg, 16px) var(--space--lg, 24px);
  }

  .content {
    display: flex;
    padding: var(--space--lg, 16px) var(--space--lg, 24px) 24px var(--space--lg, 24px);
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    margin-bottom: 0px;
  }

  .availability-text {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
    line-height: 1.5;
    min-height: 22px;
  }

  .brand-text {
    font-size: 31px;
    font-weight: 700;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .offer-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 18px;
    margin-bottom: 0;
    line-height: 1.5;
    min-height: 58px;
  }

  .expiration-text {
    font-size: 13.5px;
    font-weight: 400;
    color: #6B7280;
    line-height: 1.5;
    min-height: 25px;
  }

  .image-container {
    padding: 24px; /* Adjust padding as needed */
  }


  .offer-btn {
    width: 294px;
    height: 75px;
    font-weight: 700;
    padding: 24px;
    align-self: stretch;
    font-size: 18px;
    border-radius: 0;
  }

  .offer-image-container {
    padding-top: 24px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .offer-image {
    width: 186px;
    height: 186px;
  }

  &.mobile-two-columns {
    @media (max-width: 600px) {
      width: 100%;
      height: 396px;
  
      .offer-img {
        width: 100%;
        height: auto;
      }
  
      .offer-btn {
        width: 100%;
        height: auto;
        font-size: 16px;
        padding: 24px;
      }
  
      .brand-text {
        font-size: 21px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
  
      .offer-description {
        font-size: 16px;
        min-height: 48px;
      }
  
      .offer-image-container {
        padding: 8px;
        width: 183px;
        height: 175px;
      }
  
      .offer-image {
        height: 167px;
        width: 167px;
      }
  
      .availability-text {
        min-height: 13.5px;
      }
  
      .expiration-text {
        min-height: auto;
      }
    }
  }
}

.offerContent2 {
  .OfferList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    justify-items: center;
    max-width: 1224px;
    margin: 0 auto;
    padding: 0 8px;

    @media (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    &.mobile-two-columns {
      @media (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;
      }
    }
  }
}