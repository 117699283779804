
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');


.login-modal-card {
  display: flex;
  width: 500px;
  padding: var(--space--4xl, 64px);
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  line-height: 1.2;

  border-radius: 24px;
  background: #FFF;
  
  /* coupon shadow */
  box-shadow: 0px 0px 0px 0px rgba(29, 60, 109, 0.10), 0px 7px 15px 0px rgba(29, 60, 109, 0.10), 0px 27px 27px 0px rgba(29, 60, 109, 0.09), 0px 61px 37px 0px rgba(29, 60, 109, 0.05), 0px 109px 44px 0px rgba(29, 60, 109, 0.01), 0px 170px 48px 0px rgba(29, 60, 109, 0.00);
}

.login-modal-header {
  width: 372px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.sign-in-text {
  color: var(--black, #25272C);
  text-align: center;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 34.1px */
}
.sign-in-instructions {
  color: var(--grey, var(--Content-contentSecondary, #6B7280));
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
}
.phone-container {
  justify-content: 'center';
  display: flex;
  margin-top: 24px;
}
.phone-input {
  display: flex;
  padding: var(--space--md, 16px);
  align-items: center;
  gap: 8px;
  align-self: stretch;
  flex: 1 0 0;

  border-radius: var(--radius--md, 8px);
  border: var(--space--none, 1px) solid var(--Border-borderOpaque, #B6BAC3);
  background: var(--Background-backgroundPrimary, #FFF);
}

.remember-me-text {
  color: var(--black, #25272C);
  font-size: 18px;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
}
